import { AfterViewInit, Component } from '@angular/core';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements AfterViewInit {
  public appPages = [
    { title: 'MainMenu.DASHBOARD', url: '/dashboard', icon: 'apps' },
    { title: 'MainMenu.PROPS', url: '/props', icon: 'dice' },
    { title: 'MainMenu.ROUTINES', url: '/routines', icon: 'paper-plane' },
    { title: 'MainMenu.ACTS', url: '/folder/Favorites', icon: 'star' },
    { title: 'MainMenu.GIGS', url: '/folder/Archived', icon: 'calendar-number' },
    { title: 'MainMenu.SETTINGS', url: '/folder/Trash', icon: 'settings' },
    { title: 'MainMenu.HELP', url: '/folder/Spam', icon: 'warning' },
  ];
  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];
  constructor() { }
  ngAfterViewInit(): void {
    // if(AppComponent.isiOS()) {
    //   const main = document.getElementById('main');
    //   main.classList.remove('md');
    //   main.classList.add('ios');
    // }
  }

  public toggleDarkMode(dark: boolean) {
    console.log('Toggle', dark);
    document.body.classList.toggle('dark', dark);
  }

  public static isiOS(): boolean {
      return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
      ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    }
}
